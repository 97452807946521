import { APP_STORE_BUTTONS } from '../../../components/widgets/AppStoreLink/hooks'
import { DisplayAppBadges } from './../FlowpageLinkCard/types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { UseAppStoreLinkCardTypes } from './types'
import { appStore, playStore, playStoreAppStore } from '@app/code/src/assets'
import { isAndroidDevice, isIosDevice } from '../../../utils/main'
import { useGetPreviewLink } from '../../../hooks/useGetPreviewLink'

export const useAppStoreLinkCard = (link: LinkType, iconSrc: string): UseAppStoreLinkCardTypes => {
  const getStoreBadges = (
    ad: {
      appStoreUrl?: string
      playStoreUrl?: string
    },
    isPreview?: boolean
  ): DisplayAppBadges => {
    const isIos = isIosDevice()
    const isAndroid = isAndroidDevice()
    if (!(ad.appStoreUrl || ad.playStoreUrl)) {
      return DisplayAppBadges.NONE
    } else if (Boolean(ad.appStoreUrl) && Boolean(ad.playStoreUrl)) {
      //both OS of app available
      if ((isIos || isAndroid) && !isPreview) {
        // we can detect user OS and we're not in preview mode
        return isIos ? DisplayAppBadges.IOS : DisplayAppBadges.ANDROID
      } else {
        //we cannot detect user OS
        return DisplayAppBadges.BOTH
      }
    } else {
      //only one OS of app is availble
      return ad.appStoreUrl ? DisplayAppBadges.IOS : DisplayAppBadges.ANDROID
    }
  }
  const getParentLink = (
    ad: { appStoreUrl?: string; playStoreUrl?: string },
    displayType?: string,
    isPreview?: boolean
  ): string | undefined => {
    if (displayType === APP_STORE_BUTTONS) {
      return undefined
    }

    const appStoreBadges = getStoreBadges(ad, isPreview)
    switch (appStoreBadges) {
      case DisplayAppBadges.ANDROID:
        return ad.playStoreUrl
      case DisplayAppBadges.IOS:
      case DisplayAppBadges.BOTH:
        return ad.appStoreUrl
      default:
        return ad.appStoreUrl ?? ad.playStoreUrl
    }
  }
  const getAppLinkIconSrc = (iconSrc: string, storeBadges: DisplayAppBadges): string => {
    if (iconSrc === playStoreAppStore || iconSrc === appStore || iconSrc === playStore) {
      switch (storeBadges) {
        case DisplayAppBadges.BOTH:
        case DisplayAppBadges.NONE:
          return playStoreAppStore
        case DisplayAppBadges.IOS:
          return appStore
        case DisplayAppBadges.ANDROID:
          return playStore
        default:
          return playStoreAppStore
      }
    } else {
      return iconSrc
    }
  }
  const ad = link?.actionData ? link.actionData : { appStoreUrl: null, playStoreUrl: null }
  const previewLink = useGetPreviewLink()
  const isPreview = Boolean(previewLink)
  const appStoreBadges = getStoreBadges(ad, isPreview)
  const processedIconSrc = getAppLinkIconSrc(iconSrc, appStoreBadges)
  const parentLinkUrl = getParentLink(ad, link?.displayType ?? undefined, isPreview)

  return { appStoreBadges, processedIconSrc, parentLinkUrl }
}
