import { AppStoreLinkFormFields } from '../Destination/types'
import { DESCRIPTION_MAX, DESCRIPTION_MAX_ERROR, TITLE_MAX, TITLE_MAX_ERROR } from '../validation'
import { FormFields } from '../types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getValidatedActionData } from '../../../components/widgets/typeUtils'
import { playStoreAppStore } from '@app/code/src/assets'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview, validateLink } from '../../../components/widgets/utils'
import { useSubmitWidget } from '../submitUtils'

export const APP_STORE_BUTTONS = 'appStoreButtons'
export enum AppStoreButtonColor {
  DARK = 'dark',
  LIGHT = 'light'
}
export const useAppStoreLinkFormState = (
  order: number,
  curr: LinkType | undefined,
  handleClose: () => void,
  id: string
): any => {
  const actionData = getValidatedActionData<'appStoreLink'>(curr?.actionData, 'link')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const { TITLE, DESCRIPTION, IMAGE, DISPLAY_TYPE, BUTTON_COLOR } = FormFields
  enum AppStoreUrlFields {
    APPSTOREURL = 'appStoreUrl',
    PLAYSTOREURL = 'playStoreUrl'
  }

  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    errors,
    watch,
    trigger
  } = useForm<AppStoreLinkFormFields>({
    defaultValues: {
      appStoreUrl: curr?.actionData?.appStoreUrl || '',
      playStoreUrl: curr?.actionData?.playStoreUrl || '',
      title: curr?.title || '',
      description: curr?.description || '',
      image: curr?.thumbNailImgUrl || playStoreAppStore,
      displayType: curr?.displayType || 'default',
      buttonColor: curr?.actionData?.buttonColor || AppStoreButtonColor.DARK
    },
    mode: 'onBlur'
  })
  const submitWidget = useSubmitWidget()
  const onSubmit = async ({
    appStoreUrl,
    playStoreUrl,
    title,
    description,
    image,
    displayType,
    buttonColor
  }: AppStoreLinkFormFields): Promise<void> => {
    try {
      setSubmitting(true)
      const newActionData = { appStoreUrl, playStoreUrl, buttonColor }
      await submitWidget({
        image: image || '',
        curr,
        actionData: newActionData || {},
        linkTheme,
        widgetType: 'appStoreLink',
        fields: {
          id,
          description: description || '',
          title,
          order,
          displayType
        }
      })
      setSubmitting(false)
      handleClose()
    } catch (err) {
      console.error('Error saving widget:', err)
    }
  }
  const watchAll = watch()
  const disabled =
    (!curr && !watchAll?.appStoreUrl && !watchAll?.playStoreUrl) ||
    (watchAll?.title?.length === 0 && watchAll.displayType !== APP_STORE_BUTTONS)
  const edit = Boolean(curr)
  useEffect(
    () => {
      register(TITLE, {
        required: watchAll.displayType !== APP_STORE_BUTTONS,
        maxLength: {
          value: TITLE_MAX,
          message: TITLE_MAX_ERROR
        }
      })
      register(IMAGE)
      register(BUTTON_COLOR)
      register(DESCRIPTION, {
        maxLength: {
          value: DESCRIPTION_MAX,
          message: DESCRIPTION_MAX_ERROR
        }
      })
      register(DISPLAY_TYPE)
      register(AppStoreUrlFields.APPSTOREURL, {
        required: false,
        validate: (value: string) => {
          return !value ? true : validateLink(value)
        }
      })
      register(AppStoreUrlFields.PLAYSTOREURL, {
        required: false,
        validate: (value: string) => {
          return !value ? true : validateLink(value)
        }
      })
    }, // TODO: resolve this rule of hooks error - issue tracked in https://app.shortcut.com/flowcode/story/41455/resolve-rule-of-hooks-error-that-were-commented-out
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionData?.appStoreUrl, actionData?.playStoreUrl, watchAll, register]
  )
  const previewLink = useMemo(
    () => ({
      provider: LinkProvider.WIDGET,
      type: 'appStoreLink',
      embed: false,
      __typename: 'Link' as const,
      actionData: {
        buttonColor: watchAll.buttonColor,
        appStoreUrl: watchAll.appStoreUrl,
        playStoreUrl: watchAll.playStoreUrl
      },
      title: watchAll.title,
      description: watchAll.description,
      thumbNailImgUrl: watchAll.image,
      id,
      linkTheme,
      order,
      active: true,
      displayType: watchAll.displayType
    }),
    [id, linkTheme, order, watchAll]
  )
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )
  return {
    actionData,
    submitting,
    setSubmitting,
    register,
    setValue,
    handleSubmit,
    errors,
    watch,
    id,
    submitWidget,
    linkTheme,
    setLinkTheme: setLinkThemeValue,
    onSubmit,
    watchAll,
    disabled,
    edit,
    trigger
  }
}
