import { BoxProps } from '@dtx-company/shared-components/src'

export interface LinkCardManager {
  cardHeight: number
  iconProps: BoxProps
  isFeatured: boolean
}

export enum DisplayAppBadges {
  BOTH = 'both',
  IOS = 'ios',
  ANDROID = 'android',
  NONE = 'none'
}
