export enum DisplayAppBadges {
  BOTH = 'both',
  IOS = 'ios',
  ANDROID = 'android',
  NONE = 'none'
}
export interface UseAppStoreLinkCardTypes {
  appStoreBadges: DisplayAppBadges
  processedIconSrc: string
  parentLinkUrl?: string
}
